import React, {useEffect, useState} from 'react';
import './GameLoader.css'
import bodymovin from "lottie-web";
import {isIOS} from 'react-device-detect';

const GameLoader = () => {
    const [audio] = useState(new Audio('/music/events/loading.mp3'));

    useEffect(() => {
        if (!isIOS) {
            audio.play()
        }
    }, [])
    const loaderLottie = async (animContent) => {
        animContent.play();
    }


    useEffect(() => {
        loaderLottie(bodymovin.loadAnimation({
            container: document.getElementById('gameLoader'),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            quality: 'low',
            height: 800,
            width:2000,
            path: '../../lottie/loading/data.json',
            // initialSegment: [0, 1081],
        })).then(() => {

        })
    },[document.getElementById('gameLoader')])

    return (
        <div className="loader-container">
            <div id="gameLoader">
            </div>
            <div className="loader-container-bg">
            </div>
        </div>
    );
}

export default GameLoader
