import {combineReducers} from "redux";
import Cookies from 'universal-cookie';


const cookies = new Cookies();
const authCookie = cookies.get('accessToken');
const userData = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')).userInfo : '';
const initialIsMuted = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')).isMuted : '';

const isLoggedIn = (state = {
    status: !!authCookie,
    token: authCookie ? authCookie : null
}, action) => {
    switch (action.type) {
        case 'SignIn':
            return action.mutation;
        case 'SignOut':
            return action.mutation;
        default:
            return state;
    }
}

const userInfo = (state = userData, action) => {
    switch (action.type) {
        case 'userInfo':
            return action.mutation
        default:
            return state
    }
}

const randomColors = (state = ['text-yellow','text-pink','text-light-green', 'text-orange']) => {
    return state
}

const isMuted = (state = initialIsMuted) => {
    return !state;
}


// const FirstGameResult = (state = {}, action) => {
//     switch (action.type) {
//         case 'FirstGameScore':
//             return action.mutation
//         default:
//             return state
//     }
// }


const allReducers = combineReducers({
    isLoggedIn,
    randomColors,
    userInfo,
    isMuted
});

export default allReducers
