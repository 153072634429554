import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import './BackModal.css'
import {isIOS} from "react-device-detect";

const BackToDalModal = (props) => {

    useEffect(() => {
        let audio = new Audio('/music/events/pop.mp3')
        if (!isIOS) {
            if (!isIOS) {
                audio.play()
            }
        }
    }, [])

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.BackModal} className="back-modal">
            <Modal.Body className="text-center">
                <p className="daly-small-text mb-0" dir="auto">
                    هل تريد الخروج من اللعبة؟
                </p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center back-modal-footer">
                <button className="daly-button" onClick={() => props.backSubmit(false)}>
                    لا
                </button>
                <button className="daly-back-button" onClick={() => props.backSubmit(true)}>
                    نعم
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default BackToDalModal

