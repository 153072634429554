import {Modal} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import './MarksModal.css'
import {isIOS} from 'react-device-detect';

const MarksModal = (props) => {
    const firstName = useSelector(state => state.userInfo.character?.firstName);
    const GenderText = useSelector(state => state.userInfo.character?.gender) === 'MALE' ? {
        self: 'ه',
        letter: 'ي',
        end: 'ًا'
    } : {
        self: 'ها',
        letter: 'ت',
        end: 'ة'
    };
    const [ModalStatus, setModalStatus] = useState(0);

    useEffect(() => {
        let audio = new Audio('/music/events/pop.mp3')
        if (!isIOS) {
            audio.play()
        }
        setModalStatus(ModalStatus + 1)
    }, [])


    const nextMark = () => {
        if (ModalStatus === 6) {
            props.closeFirstLevelModal()
        } else {
            setModalStatus(ModalStatus + 1)
        }
    }
    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={props.FirstLevelModal}
               className="first-level-game-modal marks-modal">
            <Modal.Body className="text-center position-relative">
                <div className="row">
                    <div
                        className="col-12 text-center position-static">
                        <div>
                            <h4 className="daly-title">
                                ما هي مؤشرات اللعبة؟
                            </h4>
                            {ModalStatus && ModalStatus !== 6 ?
                                <>
                                    <div className="row mb-2 marks-modal-animation">
                                        <div className="col-12">
                                            <p className="daly-small-text text-yellow mb-0" dir="auto">
                                                أداء فريقك أثناء اللعب وتخطي المراحل بأقل عدد أخطاء سينعكس على المؤشرات
                                                التالية:
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mb-2 marks-modal-animation">
                                        <div className="col-9 col-sm-10 d-flex justify-content-end align-items-center">
                                            <p className="daly-small-text mb-0" dir="auto">
                                                <span className="text-orange">مؤشر الحقوق: </span> يعبّر عن مدى تمتّع
                                                جميع سكان المدينة بحقوقهم داخل المجتمع وبالتالي حصولهم على فرص متساوية.
                                            </p>
                                        </div>
                                        <div className="col-3 col-sm-2 text-center first-mark-container">
                                            <div className="brain-mark">
                                                <img src="/images/divercity/marks/1.svg" alt=""/>
                                                <div className="mark-border">
                                                </div>
                                                <CircularProgress variant="determinate" value={100}/>
                                            </div>
                                        </div>
                                    </div>
                                </> : ''}
                            {ModalStatus > 1 && ModalStatus !== 6 ?
                                <div className="row mb-2 marks-modal-animation">
                                    <div className="col-9 col-sm-10 d-flex justify-content-end align-items-center">
                                        <p className="daly-small-text mb-0" dir="auto">
                                            <span className="text-pink">مؤشر الصحّة: </span> يعبّر عن مدى تحسن صحة
                                            السكان النفسية والجسدية.
                                        </p>
                                    </div>
                                    <div className="col-3 col-sm-2 text-center first-mark-container">
                                        <div className="share-mark">
                                            <img src="/images/divercity/marks/2.svg" alt=""/>
                                            <div className="mark-border">
                                            </div>
                                            <CircularProgress variant="determinate" value={100}/>
                                        </div>
                                    </div>
                                </div> : ''}
                            {ModalStatus > 2 && ModalStatus !== 6 ?
                                <div className="row mb-2 marks-modal-animation">
                                    <div className="col-9 col-sm-10 d-flex justify-content-end align-items-center">
                                        <p className="daly-small-text mb-0" dir="auto">
                                            <span className="text-orange">مؤشر المشاركة الاجتماعية: </span>
                                            يعبّر عن مدى المشاركة بالنشاطات المختلفة داخل المجتمع.
                                        </p>
                                    </div>
                                    <div className="col-3 col-sm-2 text-center first-mark-container">
                                        <div className="heart-mark">
                                            <img src="/images/divercity/marks/4.svg" alt=""/>
                                            <div className="mark-border">
                                            </div>
                                            <CircularProgress variant="determinate" value={100}/>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {ModalStatus > 3 && ModalStatus !== 6 ?
                                <>
                                    <div className="row mb-2 marks-modal-animation">
                                        <div className="col-9 col-sm-10 d-flex justify-content-end align-items-center">
                                            <p className="daly-small-text mb-0" dir="auto">
                                                <span className="text-pink">مؤشر الوعي: </span>يساهم هذا المؤشر في رفع
                                                وعي سكان المدينة بأهمية الدمج.
                                            </p>
                                        </div>
                                        <div className="col-3 col-sm-2 text-center first-mark-container">
                                            <div className="fair-mark">
                                                <img src="/images/divercity/marks/5.svg" alt=""/>
                                                <div className="mark-border">
                                                </div>
                                                <CircularProgress variant="determinate" value={100}/>
                                            </div>
                                        </div>
                                    </div>
                                </> : ''}
                            {ModalStatus > 4 && ModalStatus !== 6 ?
                                <div className="row marks-modal-animation">
                                    <div className="col-12">
                                        <p className="daly-small-text text-yellow mb-0 marks-bottom-section" dir="auto">
                                            تجاوزك لمراحل اللعب <span className="text-pink">بأقل</span> أخطاء ممكنة
                                            سيساهم برفع هذا المؤشرات, وبالتالي رفع مؤشر الازدهار في المدينة.
                                            <div className="chart-mark first-chart-mark">
                                                <img src="/images/divercity/marks/3.svg" alt=""/>
                                                <CircularProgress variant="determinate" value={100}/>
                                            </div>
                                            <br/>
                                            نتمنى منك <span className="text-pink">التريّث</span> باتخاذ قراراتك
                                            أثناء اللعب لتحصل على تجربة <span
                                            className="text-pink">مفيدة وممتعة!</span>
                                        </p>
                                    </div>
                                </div> : ''}
                            {ModalStatus === 6 ?
                                <div className="row mb-2 marks-modal-animation last-mark-container">

                                    <div className="col-12 text-center first-mark-container last-mark">
                                        <div className="chart-mark">
                                            <img src="/images/divercity/marks/3.svg" alt=""/>
                                            <div className="mark-border">
                                            </div>
                                            <CircularProgress variant="determinate" value={100}/>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-end align-items-center mt-4">
                                        <p className="daly-small-text mb-0" dir="auto">
                                            <span className="text-yellow">مؤشر الازدهار: </span> إتمامك للمهام الخاصة
                                            بمشروع الدمج سيساهم في رفع جودة الحياة وتحسين نتائج خطط المدينة التنموية،
                                            مما سيؤثر إيجاباً على مؤشر الازدهار.
                                        </p>
                                    </div>
                                </div> : ''}
                            <div className="marks-modal-buttons-container">
                                <button onClick={() => nextMark()} className="daly-button mt-3">
                                    {ModalStatus !== 6 ? 'التالي' : 'إنهاء'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MarksModal
