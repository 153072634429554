import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import './ResetModal.css'
import {isIOS} from 'react-device-detect';

const ResetModal = (props) => {

    useEffect(() => {
        let audio = new Audio('/music/events/pop.mp3')
        if (!isIOS) {
            audio.play()
        }
    }, [])

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.ResetGameModal} className="finish-creation-modal">
            <Modal.Body className="text-center">
                <p className="daly-small-text mb-0" dir="auto">
                    هل أنت متأكد من خيارك في إعادة اللعبة؟
                </p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center finish-creation-modal-footer">
                <button className="daly-back-button" onClick={() => props.resetSubmit(true)}>
                    إعادة اللعبة
                </button>
                <button className="daly-button" onClick={() => props.resetSubmit(false)}>
                    رجوع
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResetModal

