import React, {useEffect, useState} from 'react';
import {ToastContainer} from "react-toastify";
import {useLocation} from "react-router-dom";

const ToastTemplate = () => {
    const location = useLocation()
    const [InGame, setInGame] = useState(false)

    useEffect(() => {
        if (location.pathname === '/divercity' || location.pathname === '/divercity-character' || location.pathname === '/daly-intro') {
            setInGame(true)
        } else {
            setInGame(false)
        }
    }, [location])
    return (
        <div className="App">
            <header className="App-header">
                <ToastContainer className={`${InGame ? 'daly-font' : ''}`}
                    position="top-left"
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </header>
        </div>
    );
}

export default ToastTemplate
