import React from 'react';
import './Loader.css'

const Loader = () => {
    return (
        <div className="loader-container">
            {/*<div className="divercity-loader-icon">*/}
            {/*</div>*/}
            <img className="logo-loader" src="/images/logo.svg" alt=""/>
        </div>
    );
}

export default Loader
