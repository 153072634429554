import React, {useEffect, useState} from "react";
import './TopMenu.css'
import {Dropdown, Form, FormControl, Nav, Navbar} from "react-bootstrap";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import Cookies from 'universal-cookie';
import {useDispatch, useSelector} from "react-redux";
import {ChangeUserInfo, SignOut} from "../../../../shared/redux/actions";
import BackToDalModal from "./back-to-dal-modal/BackModal";
import {isIOS, isMobile} from 'react-device-detect';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {Box, List} from "@mui/material";
import ResetModal from "../../../divercity/shared/modals/reset-modal/ResetModal";
import MarksModal from "../../../divercity/shared/modals/marks-modal/MarksModal";
import {useMutation} from "@apollo/client";
import GameLoader from "../../../divercity/shared/games-loader/GameLoader";
import mutations from "./graphql/mutations";

const TopMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cookies = new Cookies();
    const [IsShowedMenu, setIsShowedMenu] = useState(false);
    const isLoggedIn = useSelector(state => state.isLoggedIn.status);
    const isCertified = useSelector(state => state.userInfo.isCertified);
    const userCookie = useSelector(state => state.userInfo);
    const [validated, setValidated] = useState(false);
    const [InGame, setInGame] = useState(false);
    const location = useLocation()
    const [BackModal, setBackModal] = useState(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: true,
    });
    const [ResetGameModal, setResetGameModal] = useState(false);
    const [FirstLevelModal, setFirstLevelModal] = useState(false)
    const [Loading, setLoading] = useState(false);
    const [resetUserGame] = useMutation(mutations.resetUserGame);
    const userId = useSelector(state => state.userInfo._id);


    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {/*<FontAwesomeIcon icon={faVolumeUp} className="fac"/>*/}
            <List className="p-3">
                <Link to='/divercity-leaderboard' className="yellow-hover daly-mid-text">
                    <div
                        className="daly-menu-link game-menu-item d-flex justify-content-between align-items-center text-yellow flex-row-reverse game-menu-text mr-4">
                        جدول الترتيب
                        <div>
                            <img className="game-menu-icon mt-2" src="/images/divercity/leaderboard.svg" alt=""/>
                        </div>
                    </div>
                </Link>
                <button className="daly-menu-link bg-transparent border-0 yellow-hover daly-mid-text mr-4"
                        onClick={() => setFirstLevelModal(true)}>
                    <div
                        className="game-menu-item d-flex justify-content-between align-items-center text-yellow flex-row-reverse game-menu-text">
                        ما هي المؤشرات؟
                        <div>
                            <img className="game-menu-icon mt-2" src="/images/divercity/marks.svg" alt=""/>
                        </div>
                    </div>
                </button>
                <button className="daly-menu-link bg-transparent border-0 yellow-hover daly-mid-text mr-4">
                    <Link to='/services'
                          className="game-menu-item d-flex justify-content-between align-items-center text-yellow flex-row-reverse game-menu-text">
                        اتفاقية الحقوق
                        <div>
                            <img className="game-menu-icon mt-2" src="/images/divercity/agreement.svg" alt=""/>
                        </div>

                    </Link>
                </button>
                <button className="reset-game-button daly-menu-link bg-transparent border-0 yellow-hover daly-mid-text mr-4"
                        onClick={() => setResetGameModal(true)}>
                    <div
                        className="game-menu-item d-flex align-items-center justify-content-between text-yellow flex-row-reverse game-menu-text">
                        إعادة اللعبة
                        <div>
                            <img className="game-menu-icon mt-2" src="/images/divercity/reset-game.svg" alt=""/>
                        </div>
                    </div>
                </button>

                <Link to='/divercity-map' className="yellow-hover daly-mid-text back-to-map">
                    <div
                        className="d-flex justify-content-between align-items-center text-yellow flex-row-reverse game-menu-text mr-4"
                        dir="auto">
                        العودة إلى الخريطة
                    </div>
                </Link>
                <Link to='/' className="mt-2 yellow-hover daly-mid-text back-to-dal">
                    <div
                        className="d-flex justify-content-between align-items-center text-yellow flex-row-reverse game-menu-text mr-4"
                        dir="auto">
                        العودة إلى N.A.I
                    </div>
                </Link>
            </List>

        </Box>
    );

    const resetSubmit = (answer) => {
        if (answer) {
            setResetGameModal(false)
            setLoading(true)
            resetUserGame({
                variables: {
                    id: userId
                }
            }).then((res) => {
                dispatch(ChangeUserInfo({
                        ...userCookie, character: {
                            firstName: "",
                            lastName: "",
                            gender: "",
                            character: ""
                        },
                        gameProgress: {
                            averageScore: 0,
                            currentLevel: "1.1",
                            currentScores: {health: 0, sharing: 0, prosperity: 0, awareness: 0, rights: 0}
                        }
                    })
                );
                setLoading(false)
                history.push("/nai-game");
            })
        } else {
            setResetGameModal(false)
        }
    }


    const changeGame = (inGame) => {
        if (!isIOS) {
            // if (inGame) {
            //     const elem = document.getElementById("siteContainer");
            //     if (elem.requestFullscreen) {
            //         elem.requestFullscreen().catch(console.log);
            //     } else if (elem.webkitRequestFullscreen) { /* Safari */
            //         elem.webkitRequestFullscreen();
            //     } else if (elem.msRequestFullscreen) { /* IE11 */
            //         elem.msRequestFullscreen();
            //     }
            //
            //     window.screen.orientation.lock("landscape")
            //         .then(function () {
            //
            //         })
            //         .catch(function (error) {
            //         });
            // } else {
            //     if (document.fullscreenElement) {
            //         document.exitFullscreen();
            //     } else if (document.webkitExitFullscreen) { /* Safari */
            //         document.webkitExitFullscreen();
            //     } else if (document.msExitFullscreen) { /* IE11 */
            //         document.msExitFullscreen();
            //     }
            //     window.screen.orientation.unlock();
            // }

        }
    }

    useEffect(() => {
        if (document.getElementById("siteContainer")) {
            if (location.pathname.includes('divercity')) {
                setInGame(true)
                if (isMobile && isLoggedIn) {
                    changeGame(true)
                }
            } else {
                setInGame(false)
                if (isMobile && isLoggedIn) {
                    changeGame(false)
                }
            }
        }
    }, [location])

    const logout = () => {
        cookies.remove('accessToken');
        localStorage.removeItem('reduxState');
        dispatch(SignOut({
            status: false,
            token: null
        }))
        dispatch(ChangeUserInfo(""))
        history.push("/");
        setIsShowedMenu(!IsShowedMenu)
    }

    const toggleShow = () => {
        setIsShowedMenu(!IsShowedMenu)
    }

    const submitSearch = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        let searchText = event.target.search.value;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            history.push('/searchResults/' + searchText);
        }
    }

    const backToDal = () => {
        setBackModal(true)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };


    const backSubmit = (answer) => {
        setBackModal(false)
        if (answer) {
            if (isMobile && isLoggedIn) {
                // if (document.fullscreenElement) {
                //     document.exitFullscreen();
                // } else if (document.webkitExitFullscreen) { /* Safari */
                //     document.webkitExitFullscreen();
                // } else if (document.msExitFullscreen) { /* IE11 */
                //     document.msExitFullscreen();
                // }
                // window.screen.orientation.unlock();
            }
            history.push('/')
        }
    }
    return (
        <>
            {!InGame ?
                <div className="main-nav menu-container-fluid">
                    <Navbar collapseOnSelect bg="light" expand="xl">
                        <NavLink exact className="navbar-brand" to='/'>
                            <img className="nai-logo" src="/images/logo.svg" alt=""/>
                        </NavLink>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="">
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="1" to='/nai-game'>
                                    دايڤرسيتي
                                </Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="2"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('blog') || match
                                          }} to='/blogs'>تدوينات</Nav.Link>
                                {/*<Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="18"*/}
                                {/*          isActive={(match, location) => {*/}
                                {/*              return location.pathname.toLowerCase().includes('forum') || location.pathname.toLowerCase().includes('info') || match*/}
                                {/*          }} to='/forums'>منتدى</Nav.Link>*/}
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="3"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('awareness') || match
                                          }} to='/awareness'>توعية</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="4"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('research') || match
                                          }} to='/research'>أبحاث</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="5"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('training') || match
                                          }} to='/training'>تدريبات</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="6"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('press') || match
                                          }} to='/press'>مقالات</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="13"
                                          to='/services'>حقوق و قوانين</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="7"
                                          isActive={(match, location) => {
                                              return location.pathname.toLowerCase().includes('contributor') || location.pathname.toLowerCase().includes('join') || match
                                          }} to='/about'>من نحن</Nav.Link>
                                <Nav.Link as={NavLink} exact activeClassName="dal-selected" eventKey="8" to='/contact'>تواصل
                                    معنا</Nav.Link>
                            </Nav>
                            <Form inline noValidate validated={validated} onSubmit={submitSearch}>
                                <FormControl required type="text" name="search" placeholder="بحث" className="mr-sm-2"/>
                                {isLoggedIn ?
                                    <Dropdown className="menu-dropdown" show={IsShowedMenu}>
                                        <Dropdown.Toggle onClick={toggleShow} variant="transparent" id="dropdown-basic">
                                            <div
                                                className="menu-user-profile position-relative d-flex align-items-center">
                                                <span className="menu-username">{userCookie.username}</span>
                                                <img className="menu-user-image"
                                                     src={userCookie.image ? `${process.env.REACT_APP_mainEndPoint}/${userCookie.image}` : '/images/image-placeholder.svg'}
                                                     alt=""/>
                                                {isCertified ?
                                                    <img className="menu-certified-image"
                                                         src="/images/certified-account.svg"
                                                         alt=""/> : ''}
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Nav.Link as={Link} eventKey="11" onClick={toggleShow} to='/profile'
                                                      className="dropdown-item">
                                                الصفحة الشخصية
                                            </Nav.Link>
                                            <Dropdown.Item eventKey="12" onClick={logout}>تسجيل الخروج</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : <div className="menu-login-button">
                                        <Nav.Link as={Link} eventKey="9" to='/login'>
                                            دخول
                                        </Nav.Link>
                                    </div>
                                }
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                </div> :
                <div className="daly-menu">
                    {/*<button className="btn pt-0 pr-0" onClick={() => backToDal()}>*/}
                    {/*    <h5 className="text-white">*/}
                    {/*        العودة إلى دال*/}
                    {/*    </h5>*/}
                    {/*</button>*/}

                    {['القائمة'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
            }
            {InGame ?
                <BackToDalModal BackModal={BackModal} backSubmit={backSubmit}/> : ''}
            {
                ResetGameModal ?
                    <ResetModal ResetGameModal={ResetGameModal} resetSubmit={resetSubmit}/> : ''
            }
            {FirstLevelModal ?
                <MarksModal FirstLevelModal={FirstLevelModal}
                            closeFirstLevelModal={() => setFirstLevelModal(false)}/> : ''}
            {Loading ?
                <GameLoader/> : ''}

        </>
    )
}


export default TopMenu
