import {createStore} from "redux";
import allReducers from "./reducers";



const GlobalStore = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

GlobalStore.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(GlobalStore.getState()))
})

export {
    GlobalStore
}
