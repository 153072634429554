import React, {lazy, Suspense, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import TopMenu from "./app/website/layout/top-menu/TopMenu";
import Loader from "./app/website/layout/loader/Loader";
import {ApolloClient, ApolloProvider, from, InMemoryCache} from "@apollo/client";
import 'react-toastify/dist/ReactToastify.css';
import {AuthLink, HttpLink} from "./shared/graphql";
import {useDispatch, useSelector} from "react-redux";
import {CachePersistor, LocalStorageWrapper} from "apollo3-cache-persist";
import ToastTemplate from "./app/website/layout/toast-template/ToastTemplate";
import {onError} from "@apollo/client/link/error";
import Cookies from 'universal-cookie';
import {ChangeUserInfo, SignOut} from "./shared/redux/actions";
import {toast} from "react-toastify";


const Home = lazy(() => import('./app/website/home/Home'));
const PrivacyPolicy = lazy(() => import('./app/website/sections/privacy-policy/PrivacyPolicy'));
const Blogs = lazy(() => import('./app/website/sections/blogs/blogs-list/Blogs'));
const BlogDetails = lazy(() => import('./app/website/sections/blogs/blog-details/BlogDetails'));
const Awareness = lazy(() => import('./app/website/sections/awareness/awareness-list/Awareness'));
const AwarenessDetails = lazy(() => import('./app/website/sections/awareness/awareness-details/AwarenessDetails'));
const Press = lazy(() => import('./app/website/sections/press/press-list/Press'));
const PressDetails = lazy(() => import('./app/website/sections/press/press-details/PressDetails'));
const Research = lazy(() => import('./app/website/sections/research/research-list/Research'));
const ResearchDetails = lazy(() => import('./app/website/sections/research/research-details/ResearchDetails'));
const NeedSupportResearch = lazy(() => import('./app/website/sections/research/need-support-research/NeedSupportResearch'));
const DalyValley = lazy(() => import('./app/divercity/pages/divercity-intro/DivercityIntro'));
const Contact = lazy(() => import('./app/website/sections/contact/Contact'));
const About = lazy(() => import('./app/website/sections/about/About'));
const JoinDal = lazy(() => import('./app/website/sections/about/join-dal/JoinDal'));
const Training = lazy(() => import('./app/website/sections/training/training-list/Training'));
const TrainingDetails = lazy(() => import('./app/website/sections/training/training-details/TrainingDetails'));
const Contributor = lazy(() => import('./app/website/sections/about/contributor/Contributor'));
const Services = lazy(() => import('./app/website/sections/services/Services'));
const Login = lazy(() => import('./app/website/auth/login/Login'));
const VerifyAccount = lazy(() => import('./app/website/auth/verify-account/VerifyAccount'));
const ForgotPassword = lazy(() => import('./app/website/auth/forgot-password/ForgotPassword'));
const ResetPassword = lazy(() => import('./app/website/auth/reset-password/ResetPassword'));
const SignUp = lazy(() => import('./app/website/auth/sign-up/SignUp'));
const SearchResults = lazy(() => import('./app/website/sections/search/search-results/SearchResults'));
const PrivateRoute = lazy(() => import('./shared/private-routes/PrivateRoute'));
const Forum = lazy(() => import('./app/website/sections/forum/forum-list/ForumList'));
const ForumDetails = lazy(() => import('./app/website/sections/forum/forum-details/ForumDetails'));
const InfoList = lazy(() => import('./app/website/sections/forum/info-list/InfoList'));
const InfoDetails = lazy(() => import('./app/website/sections/forum/info-details/InfoDetails'));
const Leaderboard = lazy(() => import('./app/divercity/pages/leaderboard/Leaderboard'));

const App = () => {
    const isLoggedIn = useSelector(state => state.isLoggedIn.status);
    const [client, setClient] = useState({});
    const cookies = new Cookies();
    const dispatch = useDispatch();


    useEffect(() => {
        document.getElementById('mapSong')?.pause();
        document.getElementById('endSong')?.pause();
        document.getElementById('createSong')?.pause();
        document.getElementById('levelSong')?.pause();
        document.getElementById('leaderboardSong')?.pause();

        document.addEventListener("visibilitychange", function() {
            document.getElementById('mapSong')?.pause();
            document.getElementById('endSong')?.pause();
            document.getElementById('createSong')?.pause();
            document.getElementById('levelSong')?.pause();
            document.getElementById('leaderboardSong')?.pause();
        });
    }, [])

    useEffect(() => {
        const init = async () => {
            let cache = new InMemoryCache();

            let newPersistor = new CachePersistor({
                cache,
                storage: new LocalStorageWrapper(window.localStorage),
                debug: false,
                trigger: 'write',
            });
            await newPersistor.restore()
            const logoutLink = onError(({networkError, graphQLErrors}) => {
                if (networkError?.statusCode === 400 && graphQLErrors && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
                    // logout and reset store
                    cookies.remove('accessToken');
                    localStorage.removeItem('reduxState');
                    toast.error('يرجى تسجيل الدخول')
                    dispatch(SignOut({
                        status: false,
                        token: null
                    }))
                    dispatch(ChangeUserInfo(""))
                }
            })

            setClient(new ApolloClient({
                link: from([AuthLink, logoutLink, HttpLink]),
                cache: cache,
            }));
        }
        init().catch(console.error);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const ScrollToTop = () => {
        const {pathname} = useLocation();

        useEffect(() => {
            document.body.scrollTop = 0;
        }, [pathname]);

        return null;
    }


    return (
        <Router>
            <ApolloProvider client={client}>
                <div className={`site-full-container`}>
                    <TopMenu/>
                    <div className="layout-container">
                        <Suspense fallback={<Loader/>}>
                            <ScrollToTop/>
                            <Switch>
                                <Route path="/" exact component={Home}/>
                                <Route path="/blogs" exact component={Blogs}/>
                                <Route path="/blog-details/:id" exact component={BlogDetails}/>
                                <Route path="/awareness" exact component={Awareness}/>
                                <Route path="/awareness-details/:id" exact component={AwarenessDetails}/>
                                <Route path="/training" exact component={Training}/>
                                <Route path="/training-details/:id" exact component={TrainingDetails}/>
                                <Route path="/press" exact component={Press}/>
                                <Route path="/press-details/:id" exact component={PressDetails}/>
                                <Route path="/forums" exact component={Forum}/>
                                <Route path="/forum-details/:id" exact component={ForumDetails}/>
                                <Route path="/info-list/:id" exact component={InfoList}/>
                                <Route path="/info-details/:id" exact component={InfoDetails}/>
                                <Route path="/research" exact component={Research}/>
                                <Route path="/research-details/:id" exact component={ResearchDetails}/>
                                <Route path="/need-support-research/:id" exact component={NeedSupportResearch}/>
                                <Route path="/services" exact component={Services}/>
                                <Route path="/contact" exact component={Contact}/>
                                <Route path="/about" exact component={About}/>
                                <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                                <Route path="/join-dal" exact component={JoinDal}/>
                                <Route path="/nai-game" exact component={DalyValley}/>
                                <Route path="/divercity-leaderboard" exact component={Leaderboard}/>
                                <Route path="/contributor/:id" exact component={Contributor}/>
                                <Route path="/login" exact render={props => !isLoggedIn ?
                                    <Login {...props}/> :
                                    <Redirect to='/'/>
                                }/>
                                <Route path="/signup" exact render={props => !isLoggedIn ?
                                    <SignUp {...props}/> :
                                    <Redirect to='/profile'/>
                                }/>
                                <Route path="/verify-account" exact render={props => !isLoggedIn ?
                                    <VerifyAccount {...props}/> :
                                    <Redirect to='/profile'/>
                                }/>
                                <Route path="/forgot-password" exact render={props => !isLoggedIn ?
                                    <ForgotPassword {...props}/> :
                                    <Redirect to='/profile'/>
                                }/>
                                <Route path="/reset-password" exact render={props => !isLoggedIn ?
                                    <ResetPassword {...props}/> :
                                    <Redirect to='/profile'/>
                                }/>
                                <Route path="/searchResults/:name" exact component={SearchResults}/>
                                <Route path="/"
                                       render={props => isLoggedIn ?
                                           <PrivateRoute {...props}/> :
                                           <>
                                               {toast.error('الرجاء تسجيل الدخول')}
                                               <Redirect to={`/login?rto=${window.location.href.split('/')[window.location.href.split('/').length  - 1]}`}/>
                                           </>
                                       }/>
                            </Switch>
                        </Suspense>
                    </div>
                    <ToastTemplate/>
                </div>
            </ApolloProvider>
        </Router>
    );
}


export default App;
