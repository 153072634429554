import {createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const AuthCookie = cookies.get('accessToken');
const HttpLink = createHttpLink({
    uri: `${process.env.REACT_APP_graphqlPoint}`,
});
const AuthLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    // const token = AuthCookie;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: new Cookies().get('accessToken') ? `Bearer ${new Cookies().get('accessToken')}` : "",
        }
    }
});



export {
    AuthLink,
    AuthCookie,
    HttpLink,
}
