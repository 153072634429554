import gql from "graphql-tag";

const mutations = {
    resetUserGame: gql(`
      mutation resetUserGame($id: ID!) {
            resetUserGame(userId: $id) {
                status
            }
        }
    `),
}

export default mutations
