const SignIn = (data) => {
    return {
        type: 'SignIn',
        mutation: data
    }
}

const SignOut = (data) => {
    return {
        type: 'SignOut',
        mutation: data
    }
}

const ChangeUserInfo = (data) => {
    return {
        type: 'userInfo',
        mutation: data
    }
}

//
// const FirstGameScore = (data) => {
//     return {
//         type: 'FirstGameScore',
//         mutation: data
//     }
// }

export {
    SignIn,
    SignOut,
    // FirstGameScore,
    ChangeUserInfo,
}
